<template>
  <div class="redact">
    <el-card style="margin-bottom: 20px" class="box">
      <div class="head">
        <div
          class="item"
          :class="headTabActive == item.id ? 'active' : ''"
          @click="clickItem(item)"
          v-for="item in tabHead"
          :key="item.id"
        >
          <div class="title_box">
            <img
              v-if="headTabActive == 0 && item.id == 0"
              src="../../../../static/images/jr-icon-six.png"
              alt=""
            />
            <img
              v-else-if="headTabActive != 0 && item.id == 0"
              src="../../../../static/images/jr-icon-six-copy.png"
              alt=""
            />
            <img
              v-else-if="headTabActive == 1 && item.id == 1"
              src="../../../../static/images/jr-icon-circle.png"
              alt=""
            />
            <img
              v-else-if="headTabActive != 1 && item.id == 1"
              src="../../../../static/images/jr-icon-circle-copy.png"
              alt=""
            />
            {{ item.value }} ({{ item.conut }})
          </div>
          <div class="underline" v-if="headTabActive == item.id"></div>
          <div class="underline" v-else style="background-color: white"></div>
        </div>
      </div>
    </el-card>
    <div class="context">
      <!-- 资讯详情 -->
      <automaticMatching
        @getHeaderNum="getHeaderNum"
        v-if="headTabActive == 0"
        :activityId="activityId"
        :type="type"
      ></automaticMatching>
      <!-- 标签管理 -->
      <confirmSelected
        :id="activityId"
        @getHeaderNum="getHeaderNum"
        v-if="headTabActive == 1"
      ></confirmSelected>
    </div>
  </div>
</template>
<script>
import { selectMatchingActivity, selectedCompanyPage,artificialMatchingActivity } from '../../../api/activity.js'
import automaticMatching from '../components/automaticMatching.vue'
import confirmSelected from '../components/confirmSelected.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { automaticMatching, confirmSelected },
  data() {
    //这里存放数据
    return {
      tabHead: [
        {
          value: '自动匹配',
          id: 0,
          conut: 0
        }
      ],
      // tab类名控制
      headTabActive: 0,
      // 点击编辑进来的 那一项id
      activityId: null,
      id: this.$route.query.id,//活动id
      type:this.$route.query.type,//配置类型
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 点击头部table栏
    clickItem(item) {
      this.headTabActive = item.id
      // console.log(item.id)
    },
    getHeaderNum() {
      //自动匹配数据
      if(this.type==1){
        
        artificialMatchingActivity({
        pageNum: 1,
        pageSize: 10,
        activityId: this.id,
        orderByParam: '',

        queryConditions: null
      }).then(({ data: res }) => {
        this.tabHead.find((el) => el.id == 0).conut = res.data.total
        
      })
      }else{
        selectMatchingActivity({
        pageNum: 1,
        pageSize: 10,
        activityId: this.id,
        orderByParam: '',

        queryConditions: null
      }).then(({ data: res }) => {
        this.tabHead.find((el) => el.id == 0).conut = res.data.total
        
      })
      }
    
      //确认匹配企业数
      selectedCompanyPage({
        pageNum: 1,
        pageSize: 10,
        id: this.id,
        orderByParam: null,
        queryConditions: null
      }).then(({ data: res }) => {
        this.tabHead.find((el) => el.id == 1).conut = res.data.total
      })
    }
  },

  created() {
    if (this.$route.query.id) {
      this.activityId = this.id
      this.tabHead.push({
        value: '确认已选',
        id: 1,
        conut: 0
      })
    }
    this.getHeaderNum()
  },

  mounted() {}
}
</script>
<style lang="less" scoped>
.redact {
  background-color: #fff;
  border-radius: 8px;
  // width: 100%;
  // height: 100%;
  box-sizing: border-box;
  .box {
    .head {
      // width: 100%;
      height: 70px;
      box-sizing: border-box;
      margin: 0 72px 0 22px;
      padding-left: 10px;
      display: flex;
      // border-bottom: 1px solid #efefef;
      .item {
        margin-right: 50px;
        height: 100%;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        // line-height: 20px;
        /* identical to box height */

        color: #bfbfbf;
        img {
          width: 15.02px;
          height: 14.97px;
          margin-right: 5px;
        }
        .title_box {
          display: flex;
          align-items: center;
        }
      }

      .active {
        color: #333333;
        font-size: 14px;
        // border-bottom: 6px solid #4e93fb;
        border-radius: 1px;
      }
      .underline {
        margin-top: 20px;
        width: 78px;
        height: 6px;

        /* 蓝 */

        background: #4e93fb;
        border-radius: 57px;
      }
    }
    ::v-deep .el-card__body {
      padding: 0;
    }
  }
}
</style>
