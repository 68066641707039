<template>
  <div>
    <el-card style="margin-bottom: 20px" class="top_card">
      <img :src="topDetails.activityImg" alt="" class="left" />
      <div class="right">
        <div class="right_top">
          <div class="activityName">{{ topDetails.activityName }}</div>
          <div class="operation_area">
            <el-button type="primary" @click="addinSertSelectedCompany">加入已选</el-button>
            <el-button type="info" plain style="color: #4e93fb">设置关联度</el-button>
          </div>
        </div>
        <div class="label_list">
          <span v-for="(label, index) in topDetails.labels" :key="index">#{{ label }}</span>
        </div>
       
      </div>
    </el-card>
    <el-card class="bottom_card">
      <div class="relevance_box">
        <div class="relevance">
          关联度：
          <el-radio-group v-model="queryInfo.correlation" @change="search">
            <el-radio label="">全部</el-radio>
            <el-radio label="90">只选90%以上</el-radio>
            <el-radio label="80">只选80%以上</el-radio>
            <el-radio label="70">只选70%以上</el-radio>
          </el-radio-group>
        </div>
        <div class="right">
          <img src="../../../../static/images/jr-icon-volume-copy.png" alt="" />
          <span>最新刷新时间：<span style="color: #333333"></span></span>
        </div>
      </div>
      <div class="seach_box">
        <el-input
          placeholder="请输入企业名称"
          v-model="queryInfo.queryConditions"
          class="input-with-select"
        >
          <el-button class="seach" slot="append" @click="search()">搜索</el-button>
        </el-input>
     
      </div>
      <el-table
        :header-cell-style="getRowClass"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading"
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55" align="center"> </el-table-column>
        <el-table-column label="编号" width="150" align="center" prop="number"> </el-table-column>

        <el-table-column
          prop="companyLogo"
          width="150"
          label="logo"
          align="center"
          show-overflow-tooltip
        >
          <template v-slot="{ row }">
            <el-image
            v-if="row.companyLogo"
                slot="reference"
                :src="row.companyLogo"
                style="width: 40px; height: 40px; border-radius: 5px"
                fit="fit"
              ></el-image>
              <el-image
                v-else
                slot="reference"
                src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/Group%203237.png"
                style="width: 40px; height: 40px; border-radius: 5px"
                fit="fit"
              ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="companyFullName"
          label="企业名称"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column prop="createTime" label="所在城市" align="center" width="100">
          <template slot-scope="{ row }">
            {{ row.province }}
            {{ row.city }}
          </template>
        </el-table-column>

        <el-table-column prop="labelList" label="标签" align="center">
          <template v-slot="{ row }">
            <span v-for="(o, index) in row.labelList" :key="index">
              <span v-if="index < 4 && o != ''" class="office">#{{ o }}</span>
            </span>
            <el-popover placement="bottom" width="230" trigger="hover">
              <div
                class="hidden_label_box"
                style="
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-content: center;
                  flex-wrap: wrap;
                "
              >
                <span
                  class="office"
                  v-for="(o, index) in row.labelList && row.labelList.length
                    ? row.labelList.filter((el, index) => index > 3)
                    : []"
                  :key="index"
                  style="
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #4e93fb;
                    margin-right: 10px;
                  "
                  >#{{ o }}</span
                >
              </div>
              <span
                style="color: #4e93fb"
                slot="reference"
                v-if="row.labelList && row.labelList.length > 4"
                >...{{ row.labelList.length - 4 }}</span
              >
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column
          label="关联度"
          prop="correlation"
          sortable
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="correlationBox">
              <div class="correlation">{{ scope.row.correlation }}%</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="enterpriseIntro" label="企业简介" align="center">
          <template v-slot="{ row }">
            <el-popover placement="bottom" width="230" trigger="hover">
              <div>
                <span>{{ row.enterpriseIntro }}</span>
              </div>
              <div
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                "
                slot="reference"
              >
                {{ row.enterpriseIntro }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="updateName" label="操作者" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="操作时间"
          align="center"
          sortable
          width="100"
          show-overflow-tooltip
        >
        </el-table-column> -->
      </el-table>
      <div class="block">
        <el-pagination
          style="margin-top: 20px; text-align: center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { selectMatchingActivity, selectById, insertSelectedCompany,artificialMatchingActivity } from '../../../api/activity.js'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  activityId: '',//活动id
  orderByParam: '',
  correlation: '',//关联度
  queryConditions: null
})
export default {
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      
      showactivityIntroL: '',
      showactivityIntroLBol: false,

      queryInfo: { ...defaultQueryInfo },//查询条件
      topDetails: {},//活动详情
      tableData: [],//企业数据一览
      companyIds: [],
      total: 0,
      loading: false
    }
  },
  props: {
    activityId: {
      require: true,
      type: String
    },
    type:{
      type:String
    }
  },

  created() {
    this.search()
    this.getSelectById()
  },

  mounted() {},
  //方法集合
  methods: {
    //活动详情
    async getSelectById() {
      console.log(this.activityId)
      const { data: res } = await selectById({ id: this.activityId })

      if (res.resultCode == 200) {
        this.topDetails = res.data
      }
    },

    async search() {
      this.loading = true
      this.queryInfo.activityId=this.activityId
      if(this.type==1){
        
        const {data:res}=await artificialMatchingActivity(this.queryInfo)
      if(res.resultCode===200){
        this.tableData = res.data.list
        this.loading = false
        if (this.tableData.length != 0) {
          this.$refs.multipleTable.toggleAllSelection()
        }
        this.total = res.data.total
      }else{
        this.loading = false
      }
      }else{
        const {data:res}=await selectMatchingActivity(this.queryInfo)
          
      if(res.resultCode===200){
        this.tableData = res.data.list
        this.loading = false
        if (this.tableData.length != 0) {
          this.$refs.multipleTable.toggleAllSelection()
        }
        this.total = res.data.total
      }else{
        this.loading = false
      }
      }
  
   
    },
 //加入已选
    async addinSertSelectedCompany() {
      if (!this.companyIds.length) {
        return this.$message.warning('请选择企业')
      }
      const { data: res } = await insertSelectedCompany({
        id: this.activityId,
        companyIds: this.companyIds
      })
      if (res.resultCode === 200) {
        this.search()
        this.$message.success('加入成功')
        this.$emit('getHeaderNum')
      } else {
        this.message.error(res.message)
      }
    },
    // 通过函数给table表头设置背景色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return 'background:#f7f8fa;'
      }
    },
    // 排序类型：默认1：投稿时间；2：操作时间；历史记录中操作时间是1
    // 表格的排序发生改变时
    handleSortChange({ column, prop, order }) {
      if (order === 'descending') {
        this.queryInfo.order = 'desc'
      } else if (order === 'ascending') {
        this.queryInfo.order = 'asc'
      } else {
        this.queryInfo.order = ''
      }
      if (prop === 'createTime') {
        this.queryInfo.orderType = '1'
      } else if (prop === 'updateTime') {
        this.queryInfo.orderType = '2'
      }
      this.search()
      console.log(column, prop, order, 'olumn, prop, order')
    },
    // table的checkbox状态改变时触发
    handleSelectionChange(val) {
      this.companyIds = val.map((el) => el.id)
    },
    // 切换单页展示数据数量
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.queryInfo.pageSize = val
      this.search()
    },
    // 切换分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.queryInfo.pageNum = val
      this.search()
    }
  }
}
</script>
<style lang="less" scoped>
.correlationBox {
  display: flex;
  align-items: center;
  justify-content: center;
  .correlation {
    background-color: #fd6161;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 24px;
    color: #fff;
    font-size: 12px;
  }
}

.top_card {
  ::v-deep .el-card__body {
    // padding: 100px;
    display: flex;
  }
  // display: flex;
  .left {
    width: 289.85px;
    height: 170.88px;
    flex-shrink: 0;
    margin-right: 15px;
  }
  .right {
    flex: 1;
    .right_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .activityName {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: bold;
        font-size: 20px;

        color: #333333;
      }
    }
    .label_list {
      span {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #4e93fb;
      }
    }
    .synopsis_box {
      margin-top: 15px;
      padding: 15px;
      // width: 1310.83px;
      // height: 65px;
      // left: 538.91px;
      // top: 272.28px;

      background: #f7f7f7;
      display: flex;
      .synopsis {
        flex-shrink: 0;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #595959;
      }
      .synopsis_content {
        flex: 1;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #333333;

        .more {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4e93fb;
        }
      }
    }
  }
}
.bottom_card {
  .relevance_box {
    display: flex;
    justify-content: space-between;
    .relevance {
    }
    .right {
      img {
        width: 20px;
        vertical-align: middle;
        margin-right: 5px;
      }
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      // line-height: 20px;
      color: #595959;
    }
  }

  .seach_box {
    display: flex;
    margin: 15px 0;
    align-items: flex-end;
    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
  .office {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4e93fb;
    margin-right: 10px;
  }
}
</style>
